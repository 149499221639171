<template>
  <section id="ticket poppins">
    <v-row justify="center" align="center">
     <v-col cols="12" lg="8">
       <v-sheet rounded class="custom-border pb-3">
         <v-tabs class="tabs" color="primary" v-model="tab" background-color="white" grow>
           <v-tab v-for="item in items" 
           :key="item" 
           active-class="active"
           class="item poppins secondary-2--text text-capitalize">
             {{item}}
           </v-tab>
         </v-tabs>
       <v-divider/>
       <v-tabs-items v-model="tab">
         <v-tab-item v-for="item in items" :key="item" >
           <v-card flat>
             <TicketForm v-if="item === 'Submit a ticket'" class="px-3" @submit="submit" :error="errors"/>
             <TicketList v-if="item === 'My Tickets'" :tickets="tickets"/>
           </v-card>
         </v-tab-item>
       </v-tabs-items>
       </v-sheet>
      </v-col>
   </v-row>
  </section>
</template>


<script>
import header from '../../constants/tabs/tickets/ticket'
import { mapActions, mapMutations, mapState } from 'vuex'
import TicketForm from '../../components/user/ticket/TicketForm.vue'
import TicketList from '../../components/user/ticket/TicketList.vue';

export default {
 components: {
   TicketForm,
   TicketList
 },
 computed: {
   ...mapState('usr', {
     tickets: (state) => state.tickets,
   }),
   ...mapState({
     errors: (state) => state.errors,
   }),
 },
 
 mounted(){
    this.getTickets()
 },

 methods: {
    ...mapActions('usr', ['getTickets', 'addTickets']),
   ...mapMutations(['alertMutation']),

   submit({params, callback}){
      this.addTickets(params).then(() => {
       callback('success')
     }).catch(e => {
       callback('error')
     })
   }
 },
 data: () => ({
   tab: null,
   items: header,
 })
}
</script>